@charset "UTF-8";
/*!
* CH Cognac
* @author: ssorin - <ssorin@dwdlc.com>
* v1.0 2022/06
*/
/*!
* KNACSS Reborn: Just keep it simple!
* @author: Alsacreations
* v8.2 2022/01
* Licence WTFPL http://www.wtfpl.net/
*/
/* ----------------------------- */
/* ==Reset (base)                */
/* ----------------------------- */
/*
 * 1. Switch to border-box model for all elements
 * 2. Avoid min-width: auto on flex and grid items
 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  /* 1 */
  min-width: 0;
  /* 2 */
}

/*
 * 1. Remove the grey highlight on links in iOS 
 * 2. Prevent orientation font changes in iOS
 * 3. Breaks words to prevent overflow in all browsers
 */
html {
  font-size: 100%;
  -webkit-tap-highlight-color: transparent;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  overflow-wrap: break-word;
  /* 3 */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  line-height: 1.5;
  background-color: #FFFFFF;
  color: #212529;
}

/*
 * Headings
 */
h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like {
  color: #212529;
}

/*
 * Links
 */
a {
  color: #6c757d;
  text-decoration: underline;
}

a:focus,
a:hover,
a:active {
  color: #212529;
  text-decoration: underline;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/*
 * Vertical rythm
 */
h1,
.h1-like,
h2,
.h2-like {
  margin-top: 0;
  margin-bottom: 1rem;
}

p,
address,
ol,
ul,
dl,
blockquote,
pre,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like,
dt,
dd {
  margin-top: 0;
  margin-bottom: 0.75rem;
}

/*
 * Nested elements
 */
ol ol,
ol ul,
ul ol,
ul ul,
li ul,
li ol,
nav ul,
nav ol,
li p,
li .p-like {
  margin-top: 0;
  margin-bottom: 0;
}

/*
 * Lists
 */
ul,
ol {
  padding-left: 1rem;
}

nav ul,
nav ol {
  list-style: none;
  padding: 0;
}

/*
 * Embed content
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img,
table,
td,
blockquote,
pre,
code,
input,
textarea,
select,
video,
svg,
iframe {
  max-width: 100%;
}

iframe,
img,
input,
select,
textarea {
  height: auto;
}

img {
  border-style: none;
}

/**
 * Remove the border on iframes in all browsers
 */
iframe {
  border-style: none;
}

/*
 * Fill color matching to text color
 */
svg:not([fill]) {
  fill: currentColor;
}

/*
 * Hide the overflow in IE
 */
svg:not(:root) {
  overflow: hidden;
}

/*
 * Tables
 */
table {
  border-collapse: collapse;
}

/*
 * Rulers
 */
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border: 0;
  border-top: 1px solid;
  margin: 1rem 0;
  clear: both;
  color: inherit;
}

/*
 * table styles
 */
table {
  width: 100%;
  max-width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  vertical-align: top;
  margin-bottom: 1rem;
}

/* 
 * Hidden but not for an assistive technology like a screen reader, Yahoo! method 
 */
.visually-hidden,
.sr-only {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

/*
 * Disable animations styles when reduced motion is enabled
 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}
/*
 * Change the cursor on busy elements in all browsers.
 */
[aria-busy=true] {
  cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers.
 */
[aria-controls] {
  cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers.
 */
[aria-disabled=true],
[disabled] {
  cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers.
 */
[aria-hidden=false][hidden] {
  display: initial;
}

[aria-hidden=false][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

/* ----------------------------- */
/* ==Reset (forms)               */
/* ----------------------------- */
/*
 * Remove the tapping delay on clickable elements in all browsers .
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/*
 * 1. Change the inconsistent appearance in all browsers.
 * 2. Add typography inheritance in all browsers.
 */
button,
input,
select,
textarea {
  margin: 0;
  background-color: transparent; /* 1 */
  color: inherit; /* 1 */
  font-family: inherit; /* 2 */
  font-size: inherit; /* 2 */
  line-height: inherit; /* 2 */
  letter-spacing: inherit; /* 2 */
  vertical-align: middle;
}

/*
 * Basic User Interface reset
 */
button,
input:not([type=radio]):not([type=checkbox]),
select,
textarea {
  border: 0;
}

/*
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre,
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

pre {
  tab-size: 2;
  white-space: pre-wrap;
  line-height: normal;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

/*
 * Show overflow in IE/Edge
 */
button,
input {
  overflow: visible;
}

/* 
 * Remove the inheritance of text transform in Firefox
 */
button,
select {
  text-transform: none;
}

/*
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

form,
fieldset {
  border: none;
}

fieldset {
  margin: 0;
  padding: 1rem;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0 0.25rem;
  border: 0;
  color: inherit;
  white-space: normal;
}

label {
  display: inline-block;
  cursor: pointer;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
  white-space: pre-wrap;
}

progress {
  display: inline-block;
  width: 100%;
  vertical-align: baseline;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}

/* ----------------------------- */
/* Form oddities                 */
/* ----------------------------- */
/*
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/*
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/*
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/*
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/*
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/*
 * Remove the additional :invalid styles in Firefox.
 */
:-moz-ui-invalid {
  box-shadow: none;
}

/* ----------------------------- */
/* ==Print (quick print reset)   */
/* ----------------------------- */
@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  body {
    width: auto;
    margin: auto;
    font-family: serif;
    font-size: 12pt;
  }
  p,
.p-like,
h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
h4,
.h4-like,
h5,
.h5-like,
h6,
.h6-like,
blockquote,
label,
ul,
ol {
    color: #000;
    margin: auto;
  }
  .print {
    display: block;
  }
  .no-print {
    display: none;
  }
  /* no orphans, no widows */
  p,
.p-like,
blockquote {
    orphans: 3;
    widows: 3;
  }
  /* no breaks inside these elements */
  blockquote,
ul,
ol {
    page-break-inside: avoid;
  }
  /* page break before main headers
  h1,
  .h1-like {
    page-break-before: always;
  }
  */
  /* no breaks after these elements */
  h1,
.h1-like,
h2,
.h2-like,
h3,
.h3-like,
caption {
    page-break-after: avoid;
  }
  a {
    color: #000;
  }
  /* displaying URLs
  a[href]::after {
    content: " (" attr(href) ")";
  }
  */
  a[href^="javascript:"]::after,
a[href^="#"]::after {
    content: "";
  }
}
/* ----------------------------  */
/* ==Layout classes              */
/* ----------------------------- */
/* Global container */
.layout-maxed {
  display: grid;
}
@media (min-width: 576px) {
  .layout-maxed {
    grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 576px) minmax(0.75rem, 1fr);
  }
}
@media (min-width: 992px) {
  .layout-maxed {
    grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 992px) minmax(0.75rem, 1fr);
  }
}
@media (min-width: 1200px) {
  .layout-maxed {
    grid-template-columns: minmax(0.75rem, 1fr) minmax(auto, 1200px) minmax(0.75rem, 1fr);
  }
}

/* Center all children */
.layout-maxed > * {
  grid-column: 2;
}

/* Hero box  */
.layout-hero {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: inherit;
}

.layout-hero > * {
  grid-column: 2;
}

/* Hero image */
.layout-hero-img {
  grid-column: 1/-1;
  justify-self: center;
  max-width: 100%;
}

/* 
 * Global utility classes 
 */
.hidden {
  display: none;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.flex-shrink {
  flex-shrink: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-no-grow {
  flex-grow: 0;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.text-bold {
  font-weight: bold;
}

.text-italic {
  font-style: italic;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-smaller {
  font-size: smaller;
}

.text-larger {
  font-size: larger;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-wrap {
  overflow-wrap: break-word;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.align-start {
  align-content: start;
}

.align-end {
  align-content: end;
}

.align-center {
  align-content: center;
}

.align-between {
  align-content: space-between;
}

.align-around {
  align-content: space-around;
}

.align-evenly {
  align-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.place-center {
  place-content: center;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-stretch {
  align-self: stretch;
}

.align-top {
  vertical-align: top;
}

.align-bottom {
  vertical-align: bottom;
}

.align-middle {
  vertical-align: middle;
}

.item-first {
  order: -100;
}

.item-last {
  order: 100;
}

@media (min-width: 576px) {
  .sm\:hidden {
    display: none;
  }
}
@media (min-width: 992px) {
  .md\:hidden {
    display: none;
  }
}
@media (min-width: 1200px) {
  .lg\:hidden {
    display: none;
  }
}
@media (min-width: 576px) {
  .sm\:block {
    display: block;
  }
}
@media (min-width: 992px) {
  .md\:block {
    display: block;
  }
}
@media (min-width: 1200px) {
  .lg\:block {
    display: block;
  }
}
@media (min-width: 576px) {
  .sm\:inline {
    display: inline;
  }
}
@media (min-width: 992px) {
  .md\:inline {
    display: inline;
  }
}
@media (min-width: 1200px) {
  .lg\:inline {
    display: inline;
  }
}
@media (min-width: 576px) {
  .sm\:inline-block {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .md\:inline-block {
    display: inline-block;
  }
}
@media (min-width: 1200px) {
  .lg\:inline-block {
    display: inline-block;
  }
}
@media (min-width: 576px) {
  .sm\:flex {
    display: flex;
  }
}
@media (min-width: 992px) {
  .md\:flex {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .lg\:flex {
    display: flex;
  }
}
@media (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 992px) {
  .md\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-row {
    flex-direction: row;
  }
}
@media (min-width: 576px) {
  .sm\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 992px) {
  .md\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-col {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .md\:flex-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-wrap {
    flex-wrap: wrap;
  }
}
@media (min-width: 576px) {
  .sm\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
@media (min-width: 992px) {
  .md\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-no-wrap {
    flex-wrap: nowrap;
  }
}
@media (min-width: 576px) {
  .sm\:flex-shrink {
    flex-shrink: 1;
  }
}
@media (min-width: 992px) {
  .md\:flex-shrink {
    flex-shrink: 1;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-shrink {
    flex-shrink: 1;
  }
}
@media (min-width: 576px) {
  .sm\:flex-no-shrink {
    flex-shrink: 0;
  }
}
@media (min-width: 992px) {
  .md\:flex-no-shrink {
    flex-shrink: 0;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-no-shrink {
    flex-shrink: 0;
  }
}
@media (min-width: 576px) {
  .sm\:flex-grow {
    flex-grow: 1;
  }
}
@media (min-width: 992px) {
  .md\:flex-grow {
    flex-grow: 1;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-grow {
    flex-grow: 1;
  }
}
@media (min-width: 576px) {
  .sm\:flex-no-grow {
    flex-grow: 0;
  }
}
@media (min-width: 992px) {
  .md\:flex-no-grow {
    flex-grow: 0;
  }
}
@media (min-width: 1200px) {
  .lg\:flex-no-grow {
    flex-grow: 0;
  }
}
@media (min-width: 576px) {
  .sm\:float-left {
    float: left;
  }
}
@media (min-width: 992px) {
  .md\:float-left {
    float: left;
  }
}
@media (min-width: 1200px) {
  .lg\:float-left {
    float: left;
  }
}
@media (min-width: 576px) {
  .sm\:float-right {
    float: right;
  }
}
@media (min-width: 992px) {
  .md\:float-right {
    float: right;
  }
}
@media (min-width: 1200px) {
  .lg\:float-right {
    float: right;
  }
}
@media (min-width: 576px) {
  .sm\:float-none {
    float: none;
  }
}
@media (min-width: 992px) {
  .md\:float-none {
    float: none;
  }
}
@media (min-width: 1200px) {
  .lg\:float-none {
    float: none;
  }
}
@media (min-width: 576px) {
  .sm\:text-bold {
    font-weight: bold;
  }
}
@media (min-width: 992px) {
  .md\:text-bold {
    font-weight: bold;
  }
}
@media (min-width: 1200px) {
  .lg\:text-bold {
    font-weight: bold;
  }
}
@media (min-width: 576px) {
  .sm\:text-italic {
    font-style: italic;
  }
}
@media (min-width: 992px) {
  .md\:text-italic {
    font-style: italic;
  }
}
@media (min-width: 1200px) {
  .lg\:text-italic {
    font-style: italic;
  }
}
@media (min-width: 576px) {
  .sm\:text-uppercase {
    text-transform: uppercase;
  }
}
@media (min-width: 992px) {
  .md\:text-uppercase {
    text-transform: uppercase;
  }
}
@media (min-width: 1200px) {
  .lg\:text-uppercase {
    text-transform: uppercase;
  }
}
@media (min-width: 576px) {
  .sm\:text-lowercase {
    text-transform: lowercase;
  }
}
@media (min-width: 992px) {
  .md\:text-lowercase {
    text-transform: lowercase;
  }
}
@media (min-width: 1200px) {
  .lg\:text-lowercase {
    text-transform: lowercase;
  }
}
@media (min-width: 576px) {
  .sm\:text-smaller {
    font-size: smaller;
  }
}
@media (min-width: 992px) {
  .md\:text-smaller {
    font-size: smaller;
  }
}
@media (min-width: 1200px) {
  .lg\:text-smaller {
    font-size: smaller;
  }
}
@media (min-width: 576px) {
  .sm\:text-larger {
    font-size: larger;
  }
}
@media (min-width: 992px) {
  .md\:text-larger {
    font-size: larger;
  }
}
@media (min-width: 1200px) {
  .lg\:text-larger {
    font-size: larger;
  }
}
@media (min-width: 576px) {
  .sm\:text-left {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .md\:text-left {
    text-align: left;
  }
}
@media (min-width: 1200px) {
  .lg\:text-left {
    text-align: left;
  }
}
@media (min-width: 576px) {
  .sm\:text-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .md\:text-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .lg\:text-center {
    text-align: center;
  }
}
@media (min-width: 576px) {
  .sm\:text-right {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .md\:text-right {
    text-align: right;
  }
}
@media (min-width: 1200px) {
  .lg\:text-right {
    text-align: right;
  }
}
@media (min-width: 576px) {
  .sm\:text-justify {
    text-align: justify;
  }
}
@media (min-width: 992px) {
  .md\:text-justify {
    text-align: justify;
  }
}
@media (min-width: 1200px) {
  .lg\:text-justify {
    text-align: justify;
  }
}
@media (min-width: 576px) {
  .sm\:text-wrap {
    overflow-wrap: break-word;
  }
}
@media (min-width: 992px) {
  .md\:text-wrap {
    overflow-wrap: break-word;
  }
}
@media (min-width: 1200px) {
  .lg\:text-wrap {
    overflow-wrap: break-word;
  }
}
@media (min-width: 576px) {
  .sm\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 992px) {
  .md\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-start {
    justify-content: flex-start;
  }
}
@media (min-width: 576px) {
  .sm\:justify-end {
    justify-content: flex-end;
  }
}
@media (min-width: 992px) {
  .md\:justify-end {
    justify-content: flex-end;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-end {
    justify-content: flex-end;
  }
}
@media (min-width: 576px) {
  .sm\:justify-center {
    justify-content: center;
  }
}
@media (min-width: 992px) {
  .md\:justify-center {
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-center {
    justify-content: center;
  }
}
@media (min-width: 576px) {
  .sm\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 992px) {
  .md\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-between {
    justify-content: space-between;
  }
}
@media (min-width: 576px) {
  .sm\:justify-around {
    justify-content: space-around;
  }
}
@media (min-width: 992px) {
  .md\:justify-around {
    justify-content: space-around;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-around {
    justify-content: space-around;
  }
}
@media (min-width: 576px) {
  .sm\:justify-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .md\:justify-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-evenly {
    justify-content: space-evenly;
  }
}
@media (min-width: 576px) {
  .sm\:justify-items-start {
    justify-items: start;
  }
}
@media (min-width: 992px) {
  .md\:justify-items-start {
    justify-items: start;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-items-start {
    justify-items: start;
  }
}
@media (min-width: 576px) {
  .sm\:justify-items-end {
    justify-items: end;
  }
}
@media (min-width: 992px) {
  .md\:justify-items-end {
    justify-items: end;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-items-end {
    justify-items: end;
  }
}
@media (min-width: 576px) {
  .sm\:justify-items-center {
    justify-items: center;
  }
}
@media (min-width: 992px) {
  .md\:justify-items-center {
    justify-items: center;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-items-center {
    justify-items: center;
  }
}
@media (min-width: 576px) {
  .sm\:align-start {
    align-content: start;
  }
}
@media (min-width: 992px) {
  .md\:align-start {
    align-content: start;
  }
}
@media (min-width: 1200px) {
  .lg\:align-start {
    align-content: start;
  }
}
@media (min-width: 576px) {
  .sm\:align-end {
    align-content: end;
  }
}
@media (min-width: 992px) {
  .md\:align-end {
    align-content: end;
  }
}
@media (min-width: 1200px) {
  .lg\:align-end {
    align-content: end;
  }
}
@media (min-width: 576px) {
  .sm\:align-center {
    align-content: center;
  }
}
@media (min-width: 992px) {
  .md\:align-center {
    align-content: center;
  }
}
@media (min-width: 1200px) {
  .lg\:align-center {
    align-content: center;
  }
}
@media (min-width: 576px) {
  .sm\:align-between {
    align-content: space-between;
  }
}
@media (min-width: 992px) {
  .md\:align-between {
    align-content: space-between;
  }
}
@media (min-width: 1200px) {
  .lg\:align-between {
    align-content: space-between;
  }
}
@media (min-width: 576px) {
  .sm\:align-around {
    align-content: space-around;
  }
}
@media (min-width: 992px) {
  .md\:align-around {
    align-content: space-around;
  }
}
@media (min-width: 1200px) {
  .lg\:align-around {
    align-content: space-around;
  }
}
@media (min-width: 576px) {
  .sm\:align-evenly {
    align-content: space-evenly;
  }
}
@media (min-width: 992px) {
  .md\:align-evenly {
    align-content: space-evenly;
  }
}
@media (min-width: 1200px) {
  .lg\:align-evenly {
    align-content: space-evenly;
  }
}
@media (min-width: 576px) {
  .sm\:align-items-start {
    align-items: flex-start;
  }
}
@media (min-width: 992px) {
  .md\:align-items-start {
    align-items: flex-start;
  }
}
@media (min-width: 1200px) {
  .lg\:align-items-start {
    align-items: flex-start;
  }
}
@media (min-width: 576px) {
  .sm\:align-items-end {
    align-items: flex-end;
  }
}
@media (min-width: 992px) {
  .md\:align-items-end {
    align-items: flex-end;
  }
}
@media (min-width: 1200px) {
  .lg\:align-items-end {
    align-items: flex-end;
  }
}
@media (min-width: 576px) {
  .sm\:align-items-center {
    align-items: center;
  }
}
@media (min-width: 992px) {
  .md\:align-items-center {
    align-items: center;
  }
}
@media (min-width: 1200px) {
  .lg\:align-items-center {
    align-items: center;
  }
}
@media (min-width: 576px) {
  .sm\:place-center {
    place-content: center;
  }
}
@media (min-width: 992px) {
  .md\:place-center {
    place-content: center;
  }
}
@media (min-width: 1200px) {
  .lg\:place-center {
    place-content: center;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-auto {
    justify-self: auto;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-auto {
    justify-self: auto;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-auto {
    justify-self: auto;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-start {
    justify-self: start;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-start {
    justify-self: start;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-start {
    justify-self: start;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-end {
    justify-self: end;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-end {
    justify-self: end;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-end {
    justify-self: end;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-center {
    justify-self: center;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-center {
    justify-self: center;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-center {
    justify-self: center;
  }
}
@media (min-width: 576px) {
  .sm\:justify-self-stretch {
    justify-self: stretch;
  }
}
@media (min-width: 992px) {
  .md\:justify-self-stretch {
    justify-self: stretch;
  }
}
@media (min-width: 1200px) {
  .lg\:justify-self-stretch {
    justify-self: stretch;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto;
  }
}
@media (min-width: 992px) {
  .md\:align-self-auto {
    align-self: auto;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-auto {
    align-self: auto;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-start {
    align-self: flex-start;
  }
}
@media (min-width: 992px) {
  .md\:align-self-start {
    align-self: flex-start;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-start {
    align-self: flex-start;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-end {
    align-self: flex-end;
  }
}
@media (min-width: 992px) {
  .md\:align-self-end {
    align-self: flex-end;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-end {
    align-self: flex-end;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-center {
    align-self: center;
  }
}
@media (min-width: 992px) {
  .md\:align-self-center {
    align-self: center;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-center {
    align-self: center;
  }
}
@media (min-width: 576px) {
  .sm\:align-self-stretch {
    align-self: stretch;
  }
}
@media (min-width: 992px) {
  .md\:align-self-stretch {
    align-self: stretch;
  }
}
@media (min-width: 1200px) {
  .lg\:align-self-stretch {
    align-self: stretch;
  }
}
@media (min-width: 576px) {
  .sm\:align-top {
    vertical-align: top;
  }
}
@media (min-width: 992px) {
  .md\:align-top {
    vertical-align: top;
  }
}
@media (min-width: 1200px) {
  .lg\:align-top {
    vertical-align: top;
  }
}
@media (min-width: 576px) {
  .sm\:align-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 992px) {
  .md\:align-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 1200px) {
  .lg\:align-bottom {
    vertical-align: bottom;
  }
}
@media (min-width: 576px) {
  .sm\:align-middle {
    vertical-align: middle;
  }
}
@media (min-width: 992px) {
  .md\:align-middle {
    vertical-align: middle;
  }
}
@media (min-width: 1200px) {
  .lg\:align-middle {
    vertical-align: middle;
  }
}
@media (min-width: 576px) {
  .sm\:item-first {
    order: -100;
  }
}
@media (min-width: 992px) {
  .md\:item-first {
    order: -100;
  }
}
@media (min-width: 1200px) {
  .lg\:item-first {
    order: -100;
  }
}
@media (min-width: 576px) {
  .sm\:item-last {
    order: 100;
  }
}
@media (min-width: 992px) {
  .md\:item-last {
    order: 100;
  }
}
@media (min-width: 1200px) {
  .lg\:item-last {
    order: 100;
  }
}
.is-unstyled {
  list-style: none;
  padding-left: 0;
}

.is-disabled,
[disabled] {
  opacity: 0.6;
  cursor: not-allowed !important;
  filter: grayscale(1);
}

.text-normal {
  font-weight: normal;
  font-style: normal;
  text-transform: none;
}

@media (min-width: 576px) {
  .sm\:visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
  .sm\:is-unstyled {
    list-style: none;
    padding-left: 0;
  }
  .sm\:is-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    filter: grayscale(1);
  }
  .sm\:text-normal {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
  }
}
@media (min-width: 992px) {
  .md\:visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
  .md\:is-unstyled {
    list-style: none;
    padding-left: 0;
  }
  .md\:is-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    filter: grayscale(1);
  }
  .md\:text-normal {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
  }
}
@media (min-width: 1200px) {
  .lg\:visually-hidden {
    position: absolute !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
  }
  .lg\:is-unstyled {
    list-style: none;
    padding-left: 0;
  }
  .lg\:is-disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
    filter: grayscale(1);
  }
  .lg\:text-normal {
    font-weight: normal;
    font-style: normal;
    text-transform: none;
  }
}
/* Font sizes utility classes */
.text-10 {
  font-size: 0.625rem;
}

.text-11 {
  font-size: 0.6875rem;
}

.text-12 {
  font-size: 0.75rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-16 {
  font-size: 1rem;
}

.text-18 {
  font-size: 1.125rem;
}

.text-20 {
  font-size: 1.25rem;
}

.text-22 {
  font-size: 1.375rem;
}

.text-24 {
  font-size: 1.5rem;
}

.text-28 {
  font-size: 1.75rem;
}

.text-30 {
  font-size: 1.875rem;
}

.text-34 {
  font-size: 2.125rem;
}

.text-36 {
  font-size: 2.25rem;
}

.text-base {
  font-size: 1rem;
}

.text-inherit {
  font-size: inherit;
}

@media (min-width: 576px) {
  .sm\:text-10 {
    font-size: 0.625rem;
  }
}
@media (min-width: 992px) {
  .md\:text-10 {
    font-size: 0.625rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-10 {
    font-size: 0.625rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-11 {
    font-size: 0.6875rem;
  }
}
@media (min-width: 992px) {
  .md\:text-11 {
    font-size: 0.6875rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-11 {
    font-size: 0.6875rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-12 {
    font-size: 0.75rem;
  }
}
@media (min-width: 992px) {
  .md\:text-12 {
    font-size: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-12 {
    font-size: 0.75rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-14 {
    font-size: 0.875rem;
  }
}
@media (min-width: 992px) {
  .md\:text-14 {
    font-size: 0.875rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-14 {
    font-size: 0.875rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-16 {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .md\:text-16 {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-16 {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-18 {
    font-size: 1.125rem;
  }
}
@media (min-width: 992px) {
  .md\:text-18 {
    font-size: 1.125rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-18 {
    font-size: 1.125rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-20 {
    font-size: 1.25rem;
  }
}
@media (min-width: 992px) {
  .md\:text-20 {
    font-size: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-20 {
    font-size: 1.25rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-22 {
    font-size: 1.375rem;
  }
}
@media (min-width: 992px) {
  .md\:text-22 {
    font-size: 1.375rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-22 {
    font-size: 1.375rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-24 {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .md\:text-24 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-24 {
    font-size: 1.5rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-28 {
    font-size: 1.75rem;
  }
}
@media (min-width: 992px) {
  .md\:text-28 {
    font-size: 1.75rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-28 {
    font-size: 1.75rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-30 {
    font-size: 1.875rem;
  }
}
@media (min-width: 992px) {
  .md\:text-30 {
    font-size: 1.875rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-30 {
    font-size: 1.875rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-34 {
    font-size: 2.125rem;
  }
}
@media (min-width: 992px) {
  .md\:text-34 {
    font-size: 2.125rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-34 {
    font-size: 2.125rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-36 {
    font-size: 2.25rem;
  }
}
@media (min-width: 992px) {
  .md\:text-36 {
    font-size: 2.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-36 {
    font-size: 2.25rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 992px) {
  .md\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 1200px) {
  .lg\:text-base {
    font-size: 1rem;
  }
}
@media (min-width: 576px) {
  .sm\:text-inherit {
    font-size: inherit;
  }
}
@media (min-width: 992px) {
  .md\:text-inherit {
    font-size: inherit;
  }
}
@media (min-width: 1200px) {
  .lg\:text-inherit {
    font-size: inherit;
  }
}
/* Spacers utility classes */
.p-0 {
  padding: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

@media (min-width: 576px) {
  .sm\:p-0 {
    padding: 0;
  }
  .sm\:pt-0 {
    padding-top: 0;
  }
  .sm\:pr-0 {
    padding-right: 0;
  }
  .sm\:pb-0 {
    padding-bottom: 0;
  }
  .sm\:pl-0 {
    padding-left: 0;
  }
  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm\:m-0 {
    margin: 0;
  }
  .sm\:mt-0 {
    margin-top: 0;
  }
  .sm\:mr-0 {
    margin-right: 0;
  }
  .sm\:mb-0 {
    margin-bottom: 0;
  }
  .sm\:ml-0 {
    margin-left: 0;
  }
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .md\:p-0 {
    padding: 0;
  }
  .md\:pt-0 {
    padding-top: 0;
  }
  .md\:pr-0 {
    padding-right: 0;
  }
  .md\:pb-0 {
    padding-bottom: 0;
  }
  .md\:pl-0 {
    padding-left: 0;
  }
  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md\:m-0 {
    margin: 0;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:mr-0 {
    margin-right: 0;
  }
  .md\:mb-0 {
    margin-bottom: 0;
  }
  .md\:ml-0 {
    margin-left: 0;
  }
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .lg\:p-0 {
    padding: 0;
  }
  .lg\:pt-0 {
    padding-top: 0;
  }
  .lg\:pr-0 {
    padding-right: 0;
  }
  .lg\:pb-0 {
    padding-bottom: 0;
  }
  .lg\:pl-0 {
    padding-left: 0;
  }
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lg\:m-0 {
    margin: 0;
  }
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:mr-0 {
    margin-right: 0;
  }
  .lg\:mb-0 {
    margin-bottom: 0;
  }
  .lg\:ml-0 {
    margin-left: 0;
  }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.p-1 {
  padding: 1px;
}

.pt-1 {
  padding-top: 1px;
}

.pr-1 {
  padding-right: 1px;
}

.pb-1 {
  padding-bottom: 1px;
}

.pl-1 {
  padding-left: 1px;
}

.px-1 {
  padding-left: 1px;
  padding-right: 1px;
}

.py-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}

.m-1 {
  margin: 1px;
}

.mt-1 {
  margin-top: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.mb-1 {
  margin-bottom: 1px;
}

.ml-1 {
  margin-left: 1px;
}

.mx-1 {
  margin-left: 1px;
  margin-right: 1px;
}

.my-1 {
  margin-top: 1px;
  margin-bottom: 1px;
}

@media (min-width: 576px) {
  .sm\:p-1 {
    padding: 1px;
  }
  .sm\:pt-1 {
    padding-top: 1px;
  }
  .sm\:pr-1 {
    padding-right: 1px;
  }
  .sm\:pb-1 {
    padding-bottom: 1px;
  }
  .sm\:pl-1 {
    padding-left: 1px;
  }
  .sm\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:m-1 {
    margin: 1px;
  }
  .sm\:mt-1 {
    margin-top: 1px;
  }
  .sm\:mr-1 {
    margin-right: 1px;
  }
  .sm\:mb-1 {
    margin-bottom: 1px;
  }
  .sm\:ml-1 {
    margin-left: 1px;
  }
  .sm\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .sm\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
@media (min-width: 992px) {
  .md\:p-1 {
    padding: 1px;
  }
  .md\:pt-1 {
    padding-top: 1px;
  }
  .md\:pr-1 {
    padding-right: 1px;
  }
  .md\:pb-1 {
    padding-bottom: 1px;
  }
  .md\:pl-1 {
    padding-left: 1px;
  }
  .md\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:m-1 {
    margin: 1px;
  }
  .md\:mt-1 {
    margin-top: 1px;
  }
  .md\:mr-1 {
    margin-right: 1px;
  }
  .md\:mb-1 {
    margin-bottom: 1px;
  }
  .md\:ml-1 {
    margin-left: 1px;
  }
  .md\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .md\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
@media (min-width: 1200px) {
  .lg\:p-1 {
    padding: 1px;
  }
  .lg\:pt-1 {
    padding-top: 1px;
  }
  .lg\:pr-1 {
    padding-right: 1px;
  }
  .lg\:pb-1 {
    padding-bottom: 1px;
  }
  .lg\:pl-1 {
    padding-left: 1px;
  }
  .lg\:px-1 {
    padding-left: 1px;
    padding-right: 1px;
  }
  .lg\:py-1 {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .lg\:m-1 {
    margin: 1px;
  }
  .lg\:mt-1 {
    margin-top: 1px;
  }
  .lg\:mr-1 {
    margin-right: 1px;
  }
  .lg\:mb-1 {
    margin-bottom: 1px;
  }
  .lg\:ml-1 {
    margin-left: 1px;
  }
  .lg\:mx-1 {
    margin-left: 1px;
    margin-right: 1px;
  }
  .lg\:my-1 {
    margin-top: 1px;
    margin-bottom: 1px;
  }
}
.p-2 {
  padding: 0.125rem;
}

.pt-2 {
  padding-top: 0.125rem;
}

.pr-2 {
  padding-right: 0.125rem;
}

.pb-2 {
  padding-bottom: 0.125rem;
}

.pl-2 {
  padding-left: 0.125rem;
}

.px-2 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.py-2 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.m-2 {
  margin: 0.125rem;
}

.mt-2 {
  margin-top: 0.125rem;
}

.mr-2 {
  margin-right: 0.125rem;
}

.mb-2 {
  margin-bottom: 0.125rem;
}

.ml-2 {
  margin-left: 0.125rem;
}

.mx-2 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.my-2 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}

@media (min-width: 576px) {
  .sm\:p-2 {
    padding: 0.125rem;
  }
  .sm\:pt-2 {
    padding-top: 0.125rem;
  }
  .sm\:pr-2 {
    padding-right: 0.125rem;
  }
  .sm\:pb-2 {
    padding-bottom: 0.125rem;
  }
  .sm\:pl-2 {
    padding-left: 0.125rem;
  }
  .sm\:px-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .sm\:py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:m-2 {
    margin: 0.125rem;
  }
  .sm\:mt-2 {
    margin-top: 0.125rem;
  }
  .sm\:mr-2 {
    margin-right: 0.125rem;
  }
  .sm\:mb-2 {
    margin-bottom: 0.125rem;
  }
  .sm\:ml-2 {
    margin-left: 0.125rem;
  }
  .sm\:mx-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .sm\:my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}
@media (min-width: 992px) {
  .md\:p-2 {
    padding: 0.125rem;
  }
  .md\:pt-2 {
    padding-top: 0.125rem;
  }
  .md\:pr-2 {
    padding-right: 0.125rem;
  }
  .md\:pb-2 {
    padding-bottom: 0.125rem;
  }
  .md\:pl-2 {
    padding-left: 0.125rem;
  }
  .md\:px-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .md\:py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:m-2 {
    margin: 0.125rem;
  }
  .md\:mt-2 {
    margin-top: 0.125rem;
  }
  .md\:mr-2 {
    margin-right: 0.125rem;
  }
  .md\:mb-2 {
    margin-bottom: 0.125rem;
  }
  .md\:ml-2 {
    margin-left: 0.125rem;
  }
  .md\:mx-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .md\:my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-2 {
    padding: 0.125rem;
  }
  .lg\:pt-2 {
    padding-top: 0.125rem;
  }
  .lg\:pr-2 {
    padding-right: 0.125rem;
  }
  .lg\:pb-2 {
    padding-bottom: 0.125rem;
  }
  .lg\:pl-2 {
    padding-left: 0.125rem;
  }
  .lg\:px-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .lg\:py-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .lg\:m-2 {
    margin: 0.125rem;
  }
  .lg\:mt-2 {
    margin-top: 0.125rem;
  }
  .lg\:mr-2 {
    margin-right: 0.125rem;
  }
  .lg\:mb-2 {
    margin-bottom: 0.125rem;
  }
  .lg\:ml-2 {
    margin-left: 0.125rem;
  }
  .lg\:mx-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .lg\:my-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
}
.p-4 {
  padding: 0.25rem;
}

.pt-4 {
  padding-top: 0.25rem;
}

.pr-4 {
  padding-right: 0.25rem;
}

.pb-4 {
  padding-bottom: 0.25rem;
}

.pl-4 {
  padding-left: 0.25rem;
}

.px-4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.m-4 {
  margin: 0.25rem;
}

.mt-4 {
  margin-top: 0.25rem;
}

.mr-4 {
  margin-right: 0.25rem;
}

.mb-4 {
  margin-bottom: 0.25rem;
}

.ml-4 {
  margin-left: 0.25rem;
}

.mx-4 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

@media (min-width: 576px) {
  .sm\:p-4 {
    padding: 0.25rem;
  }
  .sm\:pt-4 {
    padding-top: 0.25rem;
  }
  .sm\:pr-4 {
    padding-right: 0.25rem;
  }
  .sm\:pb-4 {
    padding-bottom: 0.25rem;
  }
  .sm\:pl-4 {
    padding-left: 0.25rem;
  }
  .sm\:px-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm\:py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:m-4 {
    margin: 0.25rem;
  }
  .sm\:mt-4 {
    margin-top: 0.25rem;
  }
  .sm\:mr-4 {
    margin-right: 0.25rem;
  }
  .sm\:mb-4 {
    margin-bottom: 0.25rem;
  }
  .sm\:ml-4 {
    margin-left: 0.25rem;
  }
  .sm\:mx-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm\:my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 992px) {
  .md\:p-4 {
    padding: 0.25rem;
  }
  .md\:pt-4 {
    padding-top: 0.25rem;
  }
  .md\:pr-4 {
    padding-right: 0.25rem;
  }
  .md\:pb-4 {
    padding-bottom: 0.25rem;
  }
  .md\:pl-4 {
    padding-left: 0.25rem;
  }
  .md\:px-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .md\:py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md\:m-4 {
    margin: 0.25rem;
  }
  .md\:mt-4 {
    margin-top: 0.25rem;
  }
  .md\:mr-4 {
    margin-right: 0.25rem;
  }
  .md\:mb-4 {
    margin-bottom: 0.25rem;
  }
  .md\:ml-4 {
    margin-left: 0.25rem;
  }
  .md\:mx-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .md\:my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-4 {
    padding: 0.25rem;
  }
  .lg\:pt-4 {
    padding-top: 0.25rem;
  }
  .lg\:pr-4 {
    padding-right: 0.25rem;
  }
  .lg\:pb-4 {
    padding-bottom: 0.25rem;
  }
  .lg\:pl-4 {
    padding-left: 0.25rem;
  }
  .lg\:px-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .lg\:py-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .lg\:m-4 {
    margin: 0.25rem;
  }
  .lg\:mt-4 {
    margin-top: 0.25rem;
  }
  .lg\:mr-4 {
    margin-right: 0.25rem;
  }
  .lg\:mb-4 {
    margin-bottom: 0.25rem;
  }
  .lg\:ml-4 {
    margin-left: 0.25rem;
  }
  .lg\:mx-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .lg\:my-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
.p-8 {
  padding: 0.5rem;
}

.pt-8 {
  padding-top: 0.5rem;
}

.pr-8 {
  padding-right: 0.5rem;
}

.pb-8 {
  padding-bottom: 0.5rem;
}

.pl-8 {
  padding-left: 0.5rem;
}

.px-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.m-8 {
  margin: 0.5rem;
}

.mt-8 {
  margin-top: 0.5rem;
}

.mr-8 {
  margin-right: 0.5rem;
}

.mb-8 {
  margin-bottom: 0.5rem;
}

.ml-8 {
  margin-left: 0.5rem;
}

.mx-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .sm\:p-8 {
    padding: 0.5rem;
  }
  .sm\:pt-8 {
    padding-top: 0.5rem;
  }
  .sm\:pr-8 {
    padding-right: 0.5rem;
  }
  .sm\:pb-8 {
    padding-bottom: 0.5rem;
  }
  .sm\:pl-8 {
    padding-left: 0.5rem;
  }
  .sm\:px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:m-8 {
    margin: 0.5rem;
  }
  .sm\:mt-8 {
    margin-top: 0.5rem;
  }
  .sm\:mr-8 {
    margin-right: 0.5rem;
  }
  .sm\:mb-8 {
    margin-bottom: 0.5rem;
  }
  .sm\:ml-8 {
    margin-left: 0.5rem;
  }
  .sm\:mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 992px) {
  .md\:p-8 {
    padding: 0.5rem;
  }
  .md\:pt-8 {
    padding-top: 0.5rem;
  }
  .md\:pr-8 {
    padding-right: 0.5rem;
  }
  .md\:pb-8 {
    padding-bottom: 0.5rem;
  }
  .md\:pl-8 {
    padding-left: 0.5rem;
  }
  .md\:px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:m-8 {
    margin: 0.5rem;
  }
  .md\:mt-8 {
    margin-top: 0.5rem;
  }
  .md\:mr-8 {
    margin-right: 0.5rem;
  }
  .md\:mb-8 {
    margin-bottom: 0.5rem;
  }
  .md\:ml-8 {
    margin-left: 0.5rem;
  }
  .md\:mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md\:my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-8 {
    padding: 0.5rem;
  }
  .lg\:pt-8 {
    padding-top: 0.5rem;
  }
  .lg\:pr-8 {
    padding-right: 0.5rem;
  }
  .lg\:pb-8 {
    padding-bottom: 0.5rem;
  }
  .lg\:pl-8 {
    padding-left: 0.5rem;
  }
  .lg\:px-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg\:py-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg\:m-8 {
    margin: 0.5rem;
  }
  .lg\:mt-8 {
    margin-top: 0.5rem;
  }
  .lg\:mr-8 {
    margin-right: 0.5rem;
  }
  .lg\:mb-8 {
    margin-bottom: 0.5rem;
  }
  .lg\:ml-8 {
    margin-left: 0.5rem;
  }
  .lg\:mx-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .lg\:my-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
.p-12 {
  padding: 0.75rem;
}

.pt-12 {
  padding-top: 0.75rem;
}

.pr-12 {
  padding-right: 0.75rem;
}

.pb-12 {
  padding-bottom: 0.75rem;
}

.pl-12 {
  padding-left: 0.75rem;
}

.px-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.m-12 {
  margin: 0.75rem;
}

.mt-12 {
  margin-top: 0.75rem;
}

.mr-12 {
  margin-right: 0.75rem;
}

.mb-12 {
  margin-bottom: 0.75rem;
}

.ml-12 {
  margin-left: 0.75rem;
}

.mx-12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .sm\:p-12 {
    padding: 0.75rem;
  }
  .sm\:pt-12 {
    padding-top: 0.75rem;
  }
  .sm\:pr-12 {
    padding-right: 0.75rem;
  }
  .sm\:pb-12 {
    padding-bottom: 0.75rem;
  }
  .sm\:pl-12 {
    padding-left: 0.75rem;
  }
  .sm\:px-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:m-12 {
    margin: 0.75rem;
  }
  .sm\:mt-12 {
    margin-top: 0.75rem;
  }
  .sm\:mr-12 {
    margin-right: 0.75rem;
  }
  .sm\:mb-12 {
    margin-bottom: 0.75rem;
  }
  .sm\:ml-12 {
    margin-left: 0.75rem;
  }
  .sm\:mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .sm\:my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 992px) {
  .md\:p-12 {
    padding: 0.75rem;
  }
  .md\:pt-12 {
    padding-top: 0.75rem;
  }
  .md\:pr-12 {
    padding-right: 0.75rem;
  }
  .md\:pb-12 {
    padding-bottom: 0.75rem;
  }
  .md\:pl-12 {
    padding-left: 0.75rem;
  }
  .md\:px-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .md\:py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:m-12 {
    margin: 0.75rem;
  }
  .md\:mt-12 {
    margin-top: 0.75rem;
  }
  .md\:mr-12 {
    margin-right: 0.75rem;
  }
  .md\:mb-12 {
    margin-bottom: 0.75rem;
  }
  .md\:ml-12 {
    margin-left: 0.75rem;
  }
  .md\:mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .md\:my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-12 {
    padding: 0.75rem;
  }
  .lg\:pt-12 {
    padding-top: 0.75rem;
  }
  .lg\:pr-12 {
    padding-right: 0.75rem;
  }
  .lg\:pb-12 {
    padding-bottom: 0.75rem;
  }
  .lg\:pl-12 {
    padding-left: 0.75rem;
  }
  .lg\:px-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .lg\:py-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .lg\:m-12 {
    margin: 0.75rem;
  }
  .lg\:mt-12 {
    margin-top: 0.75rem;
  }
  .lg\:mr-12 {
    margin-right: 0.75rem;
  }
  .lg\:mb-12 {
    margin-bottom: 0.75rem;
  }
  .lg\:ml-12 {
    margin-left: 0.75rem;
  }
  .lg\:mx-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .lg\:my-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
.p-16 {
  padding: 1rem;
}

.pt-16 {
  padding-top: 1rem;
}

.pr-16 {
  padding-right: 1rem;
}

.pb-16 {
  padding-bottom: 1rem;
}

.pl-16 {
  padding-left: 1rem;
}

.px-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.m-16 {
  margin: 1rem;
}

.mt-16 {
  margin-top: 1rem;
}

.mr-16 {
  margin-right: 1rem;
}

.mb-16 {
  margin-bottom: 1rem;
}

.ml-16 {
  margin-left: 1rem;
}

.mx-16 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .sm\:p-16 {
    padding: 1rem;
  }
  .sm\:pt-16 {
    padding-top: 1rem;
  }
  .sm\:pr-16 {
    padding-right: 1rem;
  }
  .sm\:pb-16 {
    padding-bottom: 1rem;
  }
  .sm\:pl-16 {
    padding-left: 1rem;
  }
  .sm\:px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:m-16 {
    margin: 1rem;
  }
  .sm\:mt-16 {
    margin-top: 1rem;
  }
  .sm\:mr-16 {
    margin-right: 1rem;
  }
  .sm\:mb-16 {
    margin-bottom: 1rem;
  }
  .sm\:ml-16 {
    margin-left: 1rem;
  }
  .sm\:mx-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm\:my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 992px) {
  .md\:p-16 {
    padding: 1rem;
  }
  .md\:pt-16 {
    padding-top: 1rem;
  }
  .md\:pr-16 {
    padding-right: 1rem;
  }
  .md\:pb-16 {
    padding-bottom: 1rem;
  }
  .md\:pl-16 {
    padding-left: 1rem;
  }
  .md\:px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:m-16 {
    margin: 1rem;
  }
  .md\:mt-16 {
    margin-top: 1rem;
  }
  .md\:mr-16 {
    margin-right: 1rem;
  }
  .md\:mb-16 {
    margin-bottom: 1rem;
  }
  .md\:ml-16 {
    margin-left: 1rem;
  }
  .md\:mx-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md\:my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-16 {
    padding: 1rem;
  }
  .lg\:pt-16 {
    padding-top: 1rem;
  }
  .lg\:pr-16 {
    padding-right: 1rem;
  }
  .lg\:pb-16 {
    padding-bottom: 1rem;
  }
  .lg\:pl-16 {
    padding-left: 1rem;
  }
  .lg\:px-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lg\:py-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .lg\:m-16 {
    margin: 1rem;
  }
  .lg\:mt-16 {
    margin-top: 1rem;
  }
  .lg\:mr-16 {
    margin-right: 1rem;
  }
  .lg\:mb-16 {
    margin-bottom: 1rem;
  }
  .lg\:ml-16 {
    margin-left: 1rem;
  }
  .lg\:mx-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg\:my-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
.p-20 {
  padding: 1.25rem;
}

.pt-20 {
  padding-top: 1.25rem;
}

.pr-20 {
  padding-right: 1.25rem;
}

.pb-20 {
  padding-bottom: 1.25rem;
}

.pl-20 {
  padding-left: 1.25rem;
}

.px-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.m-20 {
  margin: 1.25rem;
}

.mt-20 {
  margin-top: 1.25rem;
}

.mr-20 {
  margin-right: 1.25rem;
}

.mb-20 {
  margin-bottom: 1.25rem;
}

.ml-20 {
  margin-left: 1.25rem;
}

.mx-20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  .sm\:p-20 {
    padding: 1.25rem;
  }
  .sm\:pt-20 {
    padding-top: 1.25rem;
  }
  .sm\:pr-20 {
    padding-right: 1.25rem;
  }
  .sm\:pb-20 {
    padding-bottom: 1.25rem;
  }
  .sm\:pl-20 {
    padding-left: 1.25rem;
  }
  .sm\:px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:m-20 {
    margin: 1.25rem;
  }
  .sm\:mt-20 {
    margin-top: 1.25rem;
  }
  .sm\:mr-20 {
    margin-right: 1.25rem;
  }
  .sm\:mb-20 {
    margin-bottom: 1.25rem;
  }
  .sm\:ml-20 {
    margin-left: 1.25rem;
  }
  .sm\:mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sm\:my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 992px) {
  .md\:p-20 {
    padding: 1.25rem;
  }
  .md\:pt-20 {
    padding-top: 1.25rem;
  }
  .md\:pr-20 {
    padding-right: 1.25rem;
  }
  .md\:pb-20 {
    padding-bottom: 1.25rem;
  }
  .md\:pl-20 {
    padding-left: 1.25rem;
  }
  .md\:px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .md\:py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:m-20 {
    margin: 1.25rem;
  }
  .md\:mt-20 {
    margin-top: 1.25rem;
  }
  .md\:mr-20 {
    margin-right: 1.25rem;
  }
  .md\:mb-20 {
    margin-bottom: 1.25rem;
  }
  .md\:ml-20 {
    margin-left: 1.25rem;
  }
  .md\:mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .md\:my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-20 {
    padding: 1.25rem;
  }
  .lg\:pt-20 {
    padding-top: 1.25rem;
  }
  .lg\:pr-20 {
    padding-right: 1.25rem;
  }
  .lg\:pb-20 {
    padding-bottom: 1.25rem;
  }
  .lg\:pl-20 {
    padding-left: 1.25rem;
  }
  .lg\:px-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .lg\:py-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .lg\:m-20 {
    margin: 1.25rem;
  }
  .lg\:mt-20 {
    margin-top: 1.25rem;
  }
  .lg\:mr-20 {
    margin-right: 1.25rem;
  }
  .lg\:mb-20 {
    margin-bottom: 1.25rem;
  }
  .lg\:ml-20 {
    margin-left: 1.25rem;
  }
  .lg\:mx-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .lg\:my-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
}
.p-24 {
  padding: 1.5rem;
}

.pt-24 {
  padding-top: 1.5rem;
}

.pr-24 {
  padding-right: 1.5rem;
}

.pb-24 {
  padding-bottom: 1.5rem;
}

.pl-24 {
  padding-left: 1.5rem;
}

.px-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.m-24 {
  margin: 1.5rem;
}

.mt-24 {
  margin-top: 1.5rem;
}

.mr-24 {
  margin-right: 1.5rem;
}

.mb-24 {
  margin-bottom: 1.5rem;
}

.ml-24 {
  margin-left: 1.5rem;
}

.mx-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .sm\:p-24 {
    padding: 1.5rem;
  }
  .sm\:pt-24 {
    padding-top: 1.5rem;
  }
  .sm\:pr-24 {
    padding-right: 1.5rem;
  }
  .sm\:pb-24 {
    padding-bottom: 1.5rem;
  }
  .sm\:pl-24 {
    padding-left: 1.5rem;
  }
  .sm\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:m-24 {
    margin: 1.5rem;
  }
  .sm\:mt-24 {
    margin-top: 1.5rem;
  }
  .sm\:mr-24 {
    margin-right: 1.5rem;
  }
  .sm\:mb-24 {
    margin-bottom: 1.5rem;
  }
  .sm\:ml-24 {
    margin-left: 1.5rem;
  }
  .sm\:mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .sm\:my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 992px) {
  .md\:p-24 {
    padding: 1.5rem;
  }
  .md\:pt-24 {
    padding-top: 1.5rem;
  }
  .md\:pr-24 {
    padding-right: 1.5rem;
  }
  .md\:pb-24 {
    padding-bottom: 1.5rem;
  }
  .md\:pl-24 {
    padding-left: 1.5rem;
  }
  .md\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:m-24 {
    margin: 1.5rem;
  }
  .md\:mt-24 {
    margin-top: 1.5rem;
  }
  .md\:mr-24 {
    margin-right: 1.5rem;
  }
  .md\:mb-24 {
    margin-bottom: 1.5rem;
  }
  .md\:ml-24 {
    margin-left: 1.5rem;
  }
  .md\:mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .md\:my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-24 {
    padding: 1.5rem;
  }
  .lg\:pt-24 {
    padding-top: 1.5rem;
  }
  .lg\:pr-24 {
    padding-right: 1.5rem;
  }
  .lg\:pb-24 {
    padding-bottom: 1.5rem;
  }
  .lg\:pl-24 {
    padding-left: 1.5rem;
  }
  .lg\:px-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lg\:py-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .lg\:m-24 {
    margin: 1.5rem;
  }
  .lg\:mt-24 {
    margin-top: 1.5rem;
  }
  .lg\:mr-24 {
    margin-right: 1.5rem;
  }
  .lg\:mb-24 {
    margin-bottom: 1.5rem;
  }
  .lg\:ml-24 {
    margin-left: 1.5rem;
  }
  .lg\:mx-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .lg\:my-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}
.p-36 {
  padding: 2.25rem;
}

.pt-36 {
  padding-top: 2.25rem;
}

.pr-36 {
  padding-right: 2.25rem;
}

.pb-36 {
  padding-bottom: 2.25rem;
}

.pl-36 {
  padding-left: 2.25rem;
}

.px-36 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-36 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.m-36 {
  margin: 2.25rem;
}

.mt-36 {
  margin-top: 2.25rem;
}

.mr-36 {
  margin-right: 2.25rem;
}

.mb-36 {
  margin-bottom: 2.25rem;
}

.ml-36 {
  margin-left: 2.25rem;
}

.mx-36 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-36 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

@media (min-width: 576px) {
  .sm\:p-36 {
    padding: 2.25rem;
  }
  .sm\:pt-36 {
    padding-top: 2.25rem;
  }
  .sm\:pr-36 {
    padding-right: 2.25rem;
  }
  .sm\:pb-36 {
    padding-bottom: 2.25rem;
  }
  .sm\:pl-36 {
    padding-left: 2.25rem;
  }
  .sm\:px-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .sm\:py-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .sm\:m-36 {
    margin: 2.25rem;
  }
  .sm\:mt-36 {
    margin-top: 2.25rem;
  }
  .sm\:mr-36 {
    margin-right: 2.25rem;
  }
  .sm\:mb-36 {
    margin-bottom: 2.25rem;
  }
  .sm\:ml-36 {
    margin-left: 2.25rem;
  }
  .sm\:mx-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .sm\:my-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
@media (min-width: 992px) {
  .md\:p-36 {
    padding: 2.25rem;
  }
  .md\:pt-36 {
    padding-top: 2.25rem;
  }
  .md\:pr-36 {
    padding-right: 2.25rem;
  }
  .md\:pb-36 {
    padding-bottom: 2.25rem;
  }
  .md\:pl-36 {
    padding-left: 2.25rem;
  }
  .md\:px-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .md\:py-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .md\:m-36 {
    margin: 2.25rem;
  }
  .md\:mt-36 {
    margin-top: 2.25rem;
  }
  .md\:mr-36 {
    margin-right: 2.25rem;
  }
  .md\:mb-36 {
    margin-bottom: 2.25rem;
  }
  .md\:ml-36 {
    margin-left: 2.25rem;
  }
  .md\:mx-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .md\:my-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:p-36 {
    padding: 2.25rem;
  }
  .lg\:pt-36 {
    padding-top: 2.25rem;
  }
  .lg\:pr-36 {
    padding-right: 2.25rem;
  }
  .lg\:pb-36 {
    padding-bottom: 2.25rem;
  }
  .lg\:pl-36 {
    padding-left: 2.25rem;
  }
  .lg\:px-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .lg\:py-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .lg\:m-36 {
    margin: 2.25rem;
  }
  .lg\:mt-36 {
    margin-top: 2.25rem;
  }
  .lg\:mr-36 {
    margin-right: 2.25rem;
  }
  .lg\:mb-36 {
    margin-bottom: 2.25rem;
  }
  .lg\:ml-36 {
    margin-left: 2.25rem;
  }
  .lg\:mx-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .lg\:my-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
}
.p-auto {
  padding: auto;
}

.pt-auto {
  padding-top: auto;
}

.pr-auto {
  padding-right: auto;
}

.pb-auto {
  padding-bottom: auto;
}

.pl-auto {
  padding-left: auto;
}

.px-auto {
  padding-left: auto;
  padding-right: auto;
}

.py-auto {
  padding-top: auto;
  padding-bottom: auto;
}

.m-auto {
  margin: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

@media (min-width: 576px) {
  .sm\:p-auto {
    padding: auto;
  }
  .sm\:pt-auto {
    padding-top: auto;
  }
  .sm\:pr-auto {
    padding-right: auto;
  }
  .sm\:pb-auto {
    padding-bottom: auto;
  }
  .sm\:pl-auto {
    padding-left: auto;
  }
  .sm\:px-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .sm\:py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .sm\:m-auto {
    margin: auto;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mb-auto {
    margin-bottom: auto;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 992px) {
  .md\:p-auto {
    padding: auto;
  }
  .md\:pt-auto {
    padding-top: auto;
  }
  .md\:pr-auto {
    padding-right: auto;
  }
  .md\:pb-auto {
    padding-bottom: auto;
  }
  .md\:pl-auto {
    padding-left: auto;
  }
  .md\:px-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .md\:py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:mt-auto {
    margin-top: auto;
  }
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:mb-auto {
    margin-bottom: auto;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
@media (min-width: 1200px) {
  .lg\:p-auto {
    padding: auto;
  }
  .lg\:pt-auto {
    padding-top: auto;
  }
  .lg\:pr-auto {
    padding-right: auto;
  }
  .lg\:pb-auto {
    padding-bottom: auto;
  }
  .lg\:pl-auto {
    padding-left: auto;
  }
  .lg\:px-auto {
    padding-left: auto;
    padding-right: auto;
  }
  .lg\:py-auto {
    padding-top: auto;
    padding-bottom: auto;
  }
  .lg\:m-auto {
    margin: auto;
  }
  .lg\:mt-auto {
    margin-top: auto;
  }
  .lg\:mr-auto {
    margin-right: auto;
  }
  .lg\:mb-auto {
    margin-bottom: auto;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
}
/* -------------------------------------------------- */
/* ==Grillade : système de micro-grille  de KNACSS   */
/* ------------------------------------------------ */
.grid {
  display: grid;
}

@media (min-width: 576px) {
  .sm\:grid {
    display: grid;
  }
}
@media (min-width: 992px) {
  .md\:grid {
    display: grid;
  }
}
@media (min-width: 1200px) {
  .lg\:grid {
    display: grid;
  }
}
/* grid-template-columns initialisation */
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

/* grid-template-columns au sein des breakpoints */
@media (min-width: 576px) {
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
@media (min-width: 992px) {
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
@media (min-width: 1200px) {
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}
/* gap initialisation */
.gap-0 {
  gap: 0;
}

.gap-x-0 {
  column-gap: 0;
}

.gap-y-0 {
  row-gap: 0;
}

.gap-5 {
  gap: 0.313rem;
}

.gap-x-5 {
  column-gap: 0.313rem;
}

.gap-y-5 {
  row-gap: 0.313rem;
}

.gap-10 {
  gap: 0.625rem;
}

.gap-x-10 {
  column-gap: 0.625rem;
}

.gap-y-10 {
  row-gap: 0.625rem;
}

.gap-16 {
  gap: 1rem;
}

.gap-x-16 {
  column-gap: 1rem;
}

.gap-y-16 {
  row-gap: 1rem;
}

.gap-20 {
  gap: 1.25rem;
}

.gap-x-20 {
  column-gap: 1.25rem;
}

.gap-y-20 {
  row-gap: 1.25rem;
}

.gap-36 {
  gap: 2.25rem;
}

.gap-x-36 {
  column-gap: 2.25rem;
}

.gap-y-36 {
  row-gap: 2.25rem;
}

/* gap au sein des breakpoints */
@media (min-width: 576px) {
  .sm\:gap-0 {
    gap: 0;
  }
  .sm\:gap-x-0 {
    column-gap: 0;
  }
  .sm\:gap-y-0 {
    row-gap: 0;
  }
  .sm\:gap-5 {
    gap: 0.313rem;
  }
  .sm\:gap-x-5 {
    column-gap: 0.313rem;
  }
  .sm\:gap-y-5 {
    row-gap: 0.313rem;
  }
  .sm\:gap-10 {
    gap: 0.625rem;
  }
  .sm\:gap-x-10 {
    column-gap: 0.625rem;
  }
  .sm\:gap-y-10 {
    row-gap: 0.625rem;
  }
  .sm\:gap-16 {
    gap: 1rem;
  }
  .sm\:gap-x-16 {
    column-gap: 1rem;
  }
  .sm\:gap-y-16 {
    row-gap: 1rem;
  }
  .sm\:gap-20 {
    gap: 1.25rem;
  }
  .sm\:gap-x-20 {
    column-gap: 1.25rem;
  }
  .sm\:gap-y-20 {
    row-gap: 1.25rem;
  }
  .sm\:gap-36 {
    gap: 2.25rem;
  }
  .sm\:gap-x-36 {
    column-gap: 2.25rem;
  }
  .sm\:gap-y-36 {
    row-gap: 2.25rem;
  }
}
@media (min-width: 992px) {
  .md\:gap-0 {
    gap: 0;
  }
  .md\:gap-x-0 {
    column-gap: 0;
  }
  .md\:gap-y-0 {
    row-gap: 0;
  }
  .md\:gap-5 {
    gap: 0.313rem;
  }
  .md\:gap-x-5 {
    column-gap: 0.313rem;
  }
  .md\:gap-y-5 {
    row-gap: 0.313rem;
  }
  .md\:gap-10 {
    gap: 0.625rem;
  }
  .md\:gap-x-10 {
    column-gap: 0.625rem;
  }
  .md\:gap-y-10 {
    row-gap: 0.625rem;
  }
  .md\:gap-16 {
    gap: 1rem;
  }
  .md\:gap-x-16 {
    column-gap: 1rem;
  }
  .md\:gap-y-16 {
    row-gap: 1rem;
  }
  .md\:gap-20 {
    gap: 1.25rem;
  }
  .md\:gap-x-20 {
    column-gap: 1.25rem;
  }
  .md\:gap-y-20 {
    row-gap: 1.25rem;
  }
  .md\:gap-36 {
    gap: 2.25rem;
  }
  .md\:gap-x-36 {
    column-gap: 2.25rem;
  }
  .md\:gap-y-36 {
    row-gap: 2.25rem;
  }
}
@media (min-width: 1200px) {
  .lg\:gap-0 {
    gap: 0;
  }
  .lg\:gap-x-0 {
    column-gap: 0;
  }
  .lg\:gap-y-0 {
    row-gap: 0;
  }
  .lg\:gap-5 {
    gap: 0.313rem;
  }
  .lg\:gap-x-5 {
    column-gap: 0.313rem;
  }
  .lg\:gap-y-5 {
    row-gap: 0.313rem;
  }
  .lg\:gap-10 {
    gap: 0.625rem;
  }
  .lg\:gap-x-10 {
    column-gap: 0.625rem;
  }
  .lg\:gap-y-10 {
    row-gap: 0.625rem;
  }
  .lg\:gap-16 {
    gap: 1rem;
  }
  .lg\:gap-x-16 {
    column-gap: 1rem;
  }
  .lg\:gap-y-16 {
    row-gap: 1rem;
  }
  .lg\:gap-20 {
    gap: 1.25rem;
  }
  .lg\:gap-x-20 {
    column-gap: 1.25rem;
  }
  .lg\:gap-y-20 {
    row-gap: 1.25rem;
  }
  .lg\:gap-36 {
    gap: 2.25rem;
  }
  .lg\:gap-x-36 {
    column-gap: 2.25rem;
  }
  .lg\:gap-y-36 {
    row-gap: 2.25rem;
  }
}
/* grid-items initialisation */
.col-start-1 {
  grid-column-start: 1;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-span-1 {
  grid-column: span 1/span 1;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-span-1 {
  grid-row: span 1/span 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-span-2 {
  grid-column: span 2/span 2;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-span-2 {
  grid-row: span 2/span 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-span-3 {
  grid-column: span 3/span 3;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-span-3 {
  grid-row: span 3/span 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-span-4 {
  grid-column: span 4/span 4;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-span-4 {
  grid-row: span 4/span 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-span-5 {
  grid-column: span 5/span 5;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-span-5 {
  grid-row: span 5/span 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-span-6 {
  grid-column: span 6/span 6;
}

.col-span-full {
  grid-column: 1/-1;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-span-6 {
  grid-row: span 6/span 6;
}

/* grid-items au sein des breakpoints */
@media (min-width: 576px) {
  .sm\:col-start-1 {
    grid-column-start: 1;
  }
  .sm\:col-end-1 {
    grid-column-end: 1;
  }
  .sm\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-1 {
    grid-row-start: 1;
  }
  .sm\:row-end-1 {
    grid-row-end: 1;
  }
  .sm\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .sm\:col-start-2 {
    grid-column-start: 2;
  }
  .sm\:col-end-2 {
    grid-column-end: 2;
  }
  .sm\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-2 {
    grid-row-start: 2;
  }
  .sm\:row-end-2 {
    grid-row-end: 2;
  }
  .sm\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .sm\:col-start-3 {
    grid-column-start: 3;
  }
  .sm\:col-end-3 {
    grid-column-end: 3;
  }
  .sm\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-3 {
    grid-row-start: 3;
  }
  .sm\:row-end-3 {
    grid-row-end: 3;
  }
  .sm\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .sm\:col-start-4 {
    grid-column-start: 4;
  }
  .sm\:col-end-4 {
    grid-column-end: 4;
  }
  .sm\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-4 {
    grid-row-start: 4;
  }
  .sm\:row-end-4 {
    grid-row-end: 4;
  }
  .sm\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .sm\:col-start-5 {
    grid-column-start: 5;
  }
  .sm\:col-end-5 {
    grid-column-end: 5;
  }
  .sm\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-5 {
    grid-row-start: 5;
  }
  .sm\:row-end-5 {
    grid-row-end: 5;
  }
  .sm\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .sm\:col-start-6 {
    grid-column-start: 6;
  }
  .sm\:col-end-6 {
    grid-column-end: 6;
  }
  .sm\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:row-start-6 {
    grid-row-start: 6;
  }
  .sm\:row-end-6 {
    grid-row-end: 6;
  }
  .sm\:row-span-6 {
    grid-row: span 6/span 6;
  }
}
@media (min-width: 992px) {
  .md\:col-start-1 {
    grid-column-start: 1;
  }
  .md\:col-end-1 {
    grid-column-end: 1;
  }
  .md\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-1 {
    grid-row-start: 1;
  }
  .md\:row-end-1 {
    grid-row-end: 1;
  }
  .md\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-end-2 {
    grid-column-end: 2;
  }
  .md\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-2 {
    grid-row-start: 2;
  }
  .md\:row-end-2 {
    grid-row-end: 2;
  }
  .md\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-end-3 {
    grid-column-end: 3;
  }
  .md\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-3 {
    grid-row-start: 3;
  }
  .md\:row-end-3 {
    grid-row-end: 3;
  }
  .md\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .md\:col-start-4 {
    grid-column-start: 4;
  }
  .md\:col-end-4 {
    grid-column-end: 4;
  }
  .md\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-4 {
    grid-row-start: 4;
  }
  .md\:row-end-4 {
    grid-row-end: 4;
  }
  .md\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .md\:col-start-5 {
    grid-column-start: 5;
  }
  .md\:col-end-5 {
    grid-column-end: 5;
  }
  .md\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-5 {
    grid-row-start: 5;
  }
  .md\:row-end-5 {
    grid-row-end: 5;
  }
  .md\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .md\:col-start-6 {
    grid-column-start: 6;
  }
  .md\:col-end-6 {
    grid-column-end: 6;
  }
  .md\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:row-start-6 {
    grid-row-start: 6;
  }
  .md\:row-end-6 {
    grid-row-end: 6;
  }
  .md\:row-span-6 {
    grid-row: span 6/span 6;
  }
}
@media (min-width: 1200px) {
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-end-1 {
    grid-column-end: 1;
  }
  .lg\:col-span-1 {
    grid-column: span 1/span 1;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-1 {
    grid-row-start: 1;
  }
  .lg\:row-end-1 {
    grid-row-end: 1;
  }
  .lg\:row-span-1 {
    grid-row: span 1/span 1;
  }
  .lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-end-2 {
    grid-column-end: 2;
  }
  .lg\:col-span-2 {
    grid-column: span 2/span 2;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-2 {
    grid-row-start: 2;
  }
  .lg\:row-end-2 {
    grid-row-end: 2;
  }
  .lg\:row-span-2 {
    grid-row: span 2/span 2;
  }
  .lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-end-3 {
    grid-column-end: 3;
  }
  .lg\:col-span-3 {
    grid-column: span 3/span 3;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-3 {
    grid-row-start: 3;
  }
  .lg\:row-end-3 {
    grid-row-end: 3;
  }
  .lg\:row-span-3 {
    grid-row: span 3/span 3;
  }
  .lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-end-4 {
    grid-column-end: 4;
  }
  .lg\:col-span-4 {
    grid-column: span 4/span 4;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-4 {
    grid-row-start: 4;
  }
  .lg\:row-end-4 {
    grid-row-end: 4;
  }
  .lg\:row-span-4 {
    grid-row: span 4/span 4;
  }
  .lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-end-5 {
    grid-column-end: 5;
  }
  .lg\:col-span-5 {
    grid-column: span 5/span 5;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-5 {
    grid-row-start: 5;
  }
  .lg\:row-end-5 {
    grid-row-end: 5;
  }
  .lg\:row-span-5 {
    grid-row: span 5/span 5;
  }
  .lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-end-6 {
    grid-column-end: 6;
  }
  .lg\:col-span-6 {
    grid-column: span 6/span 6;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:row-start-6 {
    grid-row-start: 6;
  }
  .lg\:row-end-6 {
    grid-row-end: 6;
  }
  .lg\:row-span-6 {
    grid-row: span 6/span 6;
  }
}
/*
 * component nav "burger" button
 */
/*
 * HTML template example:
 * <button class="burger-button" type="button" role="button" aria-label="open/close navigation"><i></i></button>
 * activate it with a JS toggle-class to .is-active
 * see doc : https: //www.knacss.com/doc.html#buttons
 */
/*
 * burger variables (you can change them)
 */
/*
 * burger button styles
 */
.burger-button {
  padding: 0;
  background-color: transparent;
  box-shadow: none;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.burger-button > * {
  display: inline-flex;
  vertical-align: top;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(#333, #333);
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100% 5px;
  transition: 0.25s;
  transition-property: transform, background;
  will-change: transform, background;
}

.burger-button > *::before,
.burger-button > *::after {
  content: "";
  height: 5px;
  background: #333;
  transition: 0.25s;
  transition-property: transform, top;
  will-change: transform, top;
}

.burger-button:hover > * {
  background-color: transparent;
}

.burger-button:focus {
  outline: 0;
}

.burger-button.is-active > * {
  background-image: none;
  justify-content: center;
}

.burger-button.is-active > *::before {
  transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
}

.burger-button.is-active > *::after {
  transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 1rem;
  background: #fff;
  color: #000;
}

a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #6c9ab5;
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.main {
  background-color: #fff;
  padding: 1rem;
}

.pageTitle {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.6rem;
  text-align: left;
}
.pageTitle span {
  display: block;
  font-size: 1rem;
  font-weight: 100;
}

.content .pageTitle {
  display: none;
}
.content h1 {
  border-bottom: 1px solid #6c9ab5;
  display: block;
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.6rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 0;
  padding-bottom: 1rem;
  text-align: left;
  width: 100%;
}
.content h2 {
  margin-top: 3rem;
  border-bottom: 1px solid #6c9ab5;
  color: #6c9ab5;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}
.content p {
  font-size: 1.1rem;
}
.content a {
  text-decoration: underline;
}
.content pre {
  font-family: "Roboto", sans-serif;
  margin-bottom: 1rem;
  color: #000;
  font-weight: 100;
  line-height: 1.6rem;
}

.about .content h1 + p strong {
  font-weight: 400;
}
.about .content ul {
  list-style: none;
}
.about .content ul li {
  margin-bottom: 1.1rem;
}
.about .content ul li > p > em {
  color: #000;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 300;
  margin-left: -1rem;
  text-transform: uppercase;
}
.about .content ul li strong em {
  color: #385D76;
  font-style: normal;
  margin-left: 0;
}

@media screen and (min-width: 992px) {
  .main {
    padding-left: 1.5rem;
  }
  .pageTitle {
    text-align: right;
    padding: 0;
    margin: 0;
  }
  .content {
    padding-top: 3rem;
  }
  .content h1 {
    display: none;
  }
  .content h1:first-of-type, .content h2:first-of-type {
    margin-top: 0;
  }
  p + h2 {
    margin-top: 3rem !important;
  }
  .legals h1 {
    display: block;
  }
}
.aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: auto;
  background-color: #f6f6f6;
  padding: 1rem 0;
}
.aside__profile, .aside .arrowDown {
  display: none;
}
.aside__imageProfile {
  display: block;
  max-width: 210px;
  margin-bottom: 0;
  margin: 0 auto;
}
.aside__navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 1rem;
}
.aside__contact {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-weight: 100;
  font-size: 1.3em;
}
.aside__contact a:hover {
  text-decoration: none;
}
.aside__social {
  text-align: center;
}
.aside__socialLink {
  margin-right: 1.2rem;
}
.aside__socialLink:last-child {
  margin-right: 0;
}
.aside__socialLink:hover {
  text-decoration: none;
}

.about .aside {
  height: 100vh;
  padding: 1rem;
}
.about .aside__profile, .about .aside .arrowDown {
  display: block;
}

@media screen and (min-width: 992px) {
  .aside {
    position: sticky;
    display: block;
    top: 0;
    padding-right: 3rem;
    height: 100vh;
  }
  .aside:before {
    background-color: #f6f6f6;
    content: "";
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 25%;
    z-index: -1;
  }
  .aside__navigation {
    padding: 0;
    flex-direction: row-reverse;
  }
  .aside__profile {
    display: block;
    margin-top: 3rem;
    text-align: right;
  }
  .aside__imageProfile {
    display: inline-block;
  }
  .aside__contact, .aside__social {
    text-align: right;
  }
  .about .aside {
    padding-right: 3rem;
  }
  .about .aside .arrowDown {
    display: none;
  }
}
.footer {
  text-align: center;
  margin-top: 6rem;
  font-size: 0.9rem;
}
.footer__list {
  list-style: none;
}
.footer__item {
  display: inline-block;
}
.footer__link {
  text-decoration: underline;
}

.navigation {
  display: none;
  align-items: center;
  background: #f6f6f6;
  height: calc(100vh - 5.5rem);
  justify-content: center;
  justify-items: center;
  left: 0;
  margin: 0;
  padding: 1rem;
  position: absolute;
  top: 5.5rem;
  width: 100%;
}
.navigation.is-opened {
  display: flex;
}
.navigation__list {
  list-style-type: none;
  padding-bottom: 2rem;
}
.navigation__item {
  display: block;
  text-align: center;
  margin: 2rem 0;
}
.navigation__link {
  padding: 2rem;
  margin: 0;
  font-size: 2rem;
  text-transform: uppercase;
}
.navigation__link--active, .navigation__link--active:hover {
  font-weight: 400;
}

@media screen and (min-width: 992px) {
  .burger-button {
    display: none;
  }
  .navigation {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    top: 0;
    height: auto;
    background-color: #fff;
  }
  .navigation__list {
    padding-bottom: 0;
    list-style-type: none;
  }
  .navigation__item {
    display: inline-block;
    margin: 0 1rem;
  }
  .navigation__item:first-child {
    margin-left: 0;
  }
  .navigation__link {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 300;
    padding: 0;
  }
  .navigation__link:hover, .navigation__link--active {
    text-decoration: none;
    font-weight: 300;
    color: #000;
  }
  .navigation__link--active, .navigation__link--active:hover {
    font-weight: 400;
  }
  .navigation__link:after {
    content: "";
    position: relative;
    display: block;
    height: 4px;
    background: #6c9ab5;
    left: 50%;
    width: 0;
    transition: all 0.3s, visibility 0s;
    -ms-transition: all 0.3s, visibility 0s;
    -webkit-transition: all 0.3s, visibility 0s;
    -moz-transition: all 0.3s, visibility 0s;
  }
  .navigation__link--active:after {
    content: "";
    position: relative;
    display: block;
    height: 4px;
    background: #6c9ab5;
    left: 0;
    width: 100%;
  }
  .navigation__link:hover:after {
    width: 100%;
    left: 0px;
  }
  .navigation__contact {
    display: none;
  }
}
.ico {
  width: 25px;
}
.ico.cv {
  width: 20px;
}

.arrowDown {
  text-align: center;
  margin: 0 auto 2rem;
}

.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}