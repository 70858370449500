.navigation {
    display: none;
    align-items: center;
    background: #f6f6f6;
    height: calc(100vh - 5.5rem);
    justify-content: center;
    justify-items: center;
    left: 0;
    margin: 0;
    padding: 1rem;
    position: absolute;
    top: 5.5rem;
    width: 100%;
    &.is-opened {
       display: flex;
    }
    &__list {
      list-style-type: none;
      padding-bottom: 2rem;
    }
    &__item {
      display: block;
      text-align: center;
      margin: 2rem 0;
    }
    &__link {
      padding: 2rem;
      margin: 0;
      font-size: 2rem;
      text-transform: uppercase;
      &--active, &--active:hover  {
        font-weight: 400;
      }
    }
}

@media screen and (min-width: $medium) {
  .burger-button {
    display: none;
  }
  .navigation {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    top: 0;
    height: auto;
    background-color: $color0;
    &__list {
      padding-bottom: 0;
      list-style-type: none;
    }
    &__item {
      display: inline-block;
      margin: 0 1rem;
      &:first-child {
        margin-left: 0;
      }
    }
    &__link {
      color: $color1;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 300;
      padding: 0;
      &:hover, &--active {
        text-decoration: none;
        font-weight: 300;
        color: $color1;
      }
      &--active, &--active:hover  {
        font-weight: 400;
      }

      &:after {
        content: "";
        position: relative;
        display: block;
        height: 4px;
        background: $color3;
        left: 50%;
        width: 0;
        transition: all .3s,visibility 0s;
        -ms-transition: all .3s,visibility 0s;
        -webkit-transition: all .3s,visibility 0s;
        -moz-transition: all .3s,visibility 0s;
      }
      &--active:after {
        content: "";
        position: relative;
        display: block;
        height: 4px;
        background: $color3;
        left: 0;
        width: 100%;
      }
      &:hover:after {
        width: 100%;
        left: 0px;
      }

    }
    &__contact {
      display: none;
    }
  }

}


//@media screen and (max-width: $small) {
//  .burger-button {
//    display: block;
//    visibility: visible;
//    float: right;
//    margin-right: 1rem;
//  }
//
//  .navigation {
//    display: none;
//    &.is-opened {
//      display: flex;
//      position: absolute;
//      background: #f6f6f6;
//      width: 100%;
//      height: calc(100vh - 6rem);
//      top: 6rem;
//      margin: 0;
//      padding: 1rem;
//      left: 0;
//      justify-items: center;
//      justify-content: center;
//      align-items: center;
//    }
//    &__item {
//      display: block;
//      text-align: center;
//      margin: 2rem 0;
//    }
//    &__link {
//      padding: 2rem;
//      margin: 0;
//      font-size: 2rem;
//      &:after {
//        display: none;
//      }
//    }
//    .contact--mobile {
//      text-align: center;
//      display: block;
//      a {
//        color: $color1
//      }
//    }
//  }
//}