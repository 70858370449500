html {
  scroll-behavior: smooth;
}

body {
  font-family: $font;
  font-weight: 300;
  font-size: 1rem;
  background: $color0;
  color: $color1;
}

a {
  color: $color1;
  text-decoration: none;
  &:hover {
    color: $color3;
  }
}

.container {
  width: 100%;
  max-width: $large;
  margin: 0 auto;
}

.main {
  background-color: $color0;
  padding: 1rem;
}

.pageTitle {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.6rem;
  text-align: left;
  span {
    display: block;
    font-size: 1rem;
    font-weight: 100;
  }
}

.content {
  .pageTitle {
    display: none;
  }

  h1 {
    border-bottom: 1px solid $color3;
    display: block;
    font-size: 2rem;
    font-weight: 300;
    line-height: 1.6rem;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    margin-left: 0;
    padding-bottom: 1rem;
    text-align: left;
    width: 100%;
  }

  h2 {
    margin-top: 3rem;
    border-bottom: 1px solid $color3;
    color: $color3;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
  }

  p {
    font-size: 1.1rem;
    strong {

    }
  }
  a {
    text-decoration: underline;
  }
  pre {
    font-family: $font;
    margin-bottom: 1rem;
    color: #000;
    font-weight: 100;
    line-height: 1.6rem;
  }
}
.about {
  .content {
    h1 + p strong {
      font-weight: 400;
    }
    ul {
      list-style: none;
      li {
        margin-bottom: 1.1rem;
      }
      li > p > em {
        color: $color1;
        font-size: .9rem;
        font-style: normal;
        font-weight: 300;
        margin-left: -1rem;
        text-transform: uppercase;
      }
      li strong em {
        color: $color6;
        font-style: normal;
        margin-left: 0;
      }
    }
  }
}

@media screen and (min-width: $medium) {
  .main {
    padding-left: 1.5rem;
  }
  .pageTitle {
    text-align: right;
    padding: 0;
    margin: 0;
  }
  .content {
    padding-top: 3rem;
    h1 {
      display: none;
    }
    h1:first-of-type, h2:first-of-type {
      margin-top: 0;
    }
  }
  p + h2 {
      margin-top: 3rem !important;
  }
  // exceptions
  .legals h1 {
    display: block;
  }
}

