.footer {
  text-align: center;
  margin-top: 6rem;
  font-size: .9rem;
  &__list {
    list-style: none;
  }
  &__item {
    display: inline-block;
  }
  &__link {
      text-decoration: underline;
  }
}