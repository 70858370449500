.aside {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: auto;
  background-color: $color4;
  padding: 1rem 0;

  &__profile, .arrowDown {
    display: none;
  }

  &__imageProfile {
    display: block;
    max-width: 210px;
    margin-bottom: 0;
    margin: 0 auto;
  }
  &__navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
  }
  &__contact {
    text-align: center;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-weight: 100;
    font-size: 1.3em;
    a:hover {
      text-decoration: none;
    }
  }

  &__social {
    text-align: center;
    &Link {
      margin-right: 1.2rem;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.about {
  .aside {
    height: 100vh;
    padding: 1rem;
    &__profile, .arrowDown {
      display: block;
    }
  }
}

@media screen and (min-width: $medium) {
  .aside {
    position: sticky;
    display: block;
    top: 0;
    padding-right: 3rem;
    height: 100vh;
    &:before {
      background-color: $color4;
      content: "";
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 25%;
      z-index: -1;
    }
    &__navigation {
      padding: 0;
      flex-direction: row-reverse;
    }
    &__profile {
      display: block;
      margin-top: 3rem;
      text-align: right;
    }
    &__imageProfile {
      display: inline-block;
    }
    &__contact, &__social {
      text-align: right;
    }
  }
  .about {
    .aside {
      padding-right: 3rem;
      .arrowDown {
        display: none;
      }
    }
  }
}